<template>
    <b-col
        cols="12"
        v-b-modal.modal-prevent-notifications
        class="d-flex flex-column row-notif"
        style="min-height: 90px"
        @click="openModal(source)"
    >
        <div class="d-flex justify-content-between">
            <p class="mb-0 pb-0 font-weight-bold h5" v-text="source[subject]" />

            <p
                class="mb-0 pb-0 text-secondary"
                v-text="renderTime(source.received)"
            />
        </div>
        <div class="text-secondary mt-1 d-flex justify-content-between">
            <div>
                <p class="mb-0 pb-0" v-text="source[message]" />
            </div>

            <div>
                <b-badge
                    pill
                    variant="danger"
                    v-if="source.read === false"
                    v-text="1"
                />
            </div>
        </div>
        <hr class="divider" />
    </b-col>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig';
import {mapGetters} from "vuex";
export default {
    props: {
        source: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            config: useAppConfig(),
        };
    },
    computed: {
        message() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_message' : 'eng_message';
            } else return this.getLang == 'sv' ? 'swe_message' : 'eng_message';
        },
        subject() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_subject' : 'eng_subject';
            } else return this.getLang == 'sv' ? 'swe_subject' : 'eng_subject';
        },

        getLang() {
            return this.config.lang.value;
        },
      ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
    },
    methods: {
        renderTime(date) {
            if (date) {
                let receivedDate = new Date(date);
                function timeSince(date) {
                    var seconds = Math.floor((new Date() - date) / 1000);

                    var interval = seconds / 31536000;

                    if (interval > 1) {
                        return Math.floor(interval) + ' years';
                    }
                    interval = seconds / 2592000;
                    if (interval > 1) {
                        return Math.floor(interval) + ' months';
                    }
                    interval = seconds / 86400;
                    if (interval > 1) {
                        return Math.floor(interval) + ' days';
                    }
                    interval = seconds / 3600;
                    if (interval > 1) {
                        return Math.floor(interval) + ' hours';
                    }
                    interval = seconds / 60;
                    if (interval > 1) {
                        return Math.floor(interval) + ' minutes';
                    }
                    return Math.floor(seconds) + ' seconds';
                }
                let aDay = 24 * 60 * 60 * 1000;

                return `${timeSince(new Date(receivedDate - aDay))} ago`;
            }
        },

        openModal(value) {
            const {openNotifications} = useAppConfig();
            openNotifications.value = value;
        },
    },
};
</script>

<style lang="less" scoped></style>
